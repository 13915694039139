<template>
  <div v-if="settings">
    <v-card>
      <v-card-title>
        <span>{{ $t('t.CreditInsurance') }}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{ $icon('i.Checked') }}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{ $icon('i.Undo') }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Groupama') }}&nbsp;</span>
          <v-switch
            dense
            v-model="settings.groupamaIsActive"
          />
        </v-subheader>
        <v-divider></v-divider>
        <grid
          item-min-width="310px"
          class="align-center"
        >
          <v-text-field
            v-model="settings.groupamaUrl"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.ApplicationBaseURL')"
            type="url"
          ></v-text-field>

          <v-text-field
            v-model="settings.groupamaLoginApi"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.IdentifiantAPI')"
          ></v-text-field>
          <v-text-field
            v-model="settings.groupamaPasswordApi"
            :append-icon="showApiPassword ? $icon('i.Eye') : $icon('i.EyeOff')"
            :type="showApiPassword ? 'text' : 'password'"
            @click:append="showApiPassword = !showApiPassword"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.PasswordAPI')"
          >
          </v-text-field>
        </grid>
        <grid
          item-min-width="310px"
          class="align-center"
        >
          <date-picker-menu
            v-model="settings.groupamaLastCall"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.LastCall')"
          />
          <column-picker
            :attach="false"
            :clearable="true"
            :label="$t('t.Balance')"
            :filters="{ 'document-types': [['accounts'],[]]}"
            :selected-col-id.sync="computedColumnBalanceId"
            currency-disabled
            consolidation-disabled
            dense
            :rules="required"
            amt-cols-only
            no-cols-relation
            no-cols-aging
          />
        </grid>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  mounted () {
    this.load()
  },
  activated () {
    this.load()
  },
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    Grid: () => import('@/components/grid')
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    },
    computedColumnBalanceId: {
      get () {
        return this.settings.colBalanceId
      },
      set (v) {
        this.settings.colBalanceId = v
      }
    }
  },
  data () {
    return {
      showApiPassword: false,
      initialSettings: null,
      required: [
        v => !!v || this.$t('t.IsRequired')
      ],
      settings: null
    }
  },
  methods: {
    async load () {
      const r = await this.$http().get('/core/v6/settings/credit-insurance-settings')

      if (r?.data?.groupamaLastCall === '1950-01-01') {
        r.data.groupamaLastCall = null
      }

      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      const settingsCopy = Object.assign({}, this.settings)

      if (!settingsCopy.groupamaLastCall) {
        settingsCopy.groupamaLastCall = '1950-01-01'
      }

      await this.$http().post('/core/v6/settings/credit-insurance-settings', settingsCopy)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
    // verifyUrl (val) {
    //  return /^[a-z]+:\/\/[a-z0-9_-]+(?:\.[a-z0-9_-]+)*(?::[0-9]+)?(?:\/.*)?$/mi.test(val)
    // }
  }
}
</script>

<style lang="stylus">
.insurance-provider-title
  padding 1em
</style>
